import * as React from 'react';
import Carousel from 'nuka-carousel';
import { FiCircle } from 'react-icons/fi';
import { StaticImage } from 'gatsby-plugin-image';

const reviews = [
  {
    review: `I had my eyebrows tattooed with Jess, and loved the results.Jess
    was so helpful and caring at our appointments, and the salon
    environment is so calming and relaxing. Would highly recommend
    Jess at Adore Beauty Therapy`,
    name: `Emma P`,
  },
  {
    review: `My new eye brows are gaining so much attention in a good way!! 
    I received so many compliments from my friends and family. 
    It's such a self esteem boost hearing "wow you look 10 years younger". 
    Highly recommend Jess at adore beauty and cosmetic tattoo.`,
    name: `Noelene T`,
  },
  {
    review: `Jess is super friendly and professional and ensures you 
    are comfortable throughout the appointment. She was very helpful 
    in choosing the best shape colour and technique used for my brows. 
    Absolutely love my new brows and look forward to my next touch up.`,
    name: `Ruby W`,
  },
  {
    review: `Jess is amazing! She made me feel so relaxed 
    the entire time i had my brows done. I had very sparse 
    brows and she talked me though the precedure every step of the way. 
    I could not be happier with my new brows and would highly 
    recommend Jess to anyone.`,
    name: `Lisa W`,
  },
];

function ClientReview(): React.ReactElement {
  return (
    <div className="overflow-hidden bg-pink-lightest safari-hack" id="contact">
      <div className="relative w-full px-4 py-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <div className="">
          <h3 className="block text-3xl font-semibold text-center text-custom-gradient">
            See What Our Clients Are Saying
          </h3>
        </div>

        <Carousel
          autoplay
          autoplayInterval={4000}
          wrapAround={true}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          renderBottomCenterControls={({
            slideCount,
            currentSlide,
            goToSlide,
          }) => (
            <div className="">
              <ul className="flex">
                {[...Array(slideCount)].map((e, key) => (
                  <li
                    className={currentSlide == key ? 'active' : undefined}
                    key={key}
                  >
                    <button
                      type="button"
                      aria-label="slide 1 bullet"
                      onClick={() => goToSlide(key)}
                    >
                      <FiCircle className="w-3 mx-1 text-pink" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        >
          {reviews.map((item, index) => (
            <div key={index} className="mt-6">
              <div className="relative max-w-6xl pb-40 mx-auto leading-8 text-center md:pb-36 text-md">
                <div className="mt-8">
                  <div className="absolute opacity-25 left-40 md:left-0 lg:-left-4 -top-6">
                    <StaticImage
                      alt=""
                      quality={90}
                      src="../images/decoration/quote.png"
                      width={50}
                      className=""
                      placeholder="none"
                    />
                  </div>
                  <div className="mt-20">{item.review}</div>
                </div>
                <p className="italic font-semibold">- {item.name}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export { ClientReview };
